(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/app/svenskaspel-backbone-view/assets/javascripts/view.js') >= 0) return;  svs.modules.push('/components/app/svenskaspel-backbone-view/assets/javascripts/view.js');

(function(Backbone) {
  'use strict';

  Backbone.View = (function(View) {
    return View.extend({
      constructor: function(options) {
        this.options = options || {};
        View.apply(this, arguments);
      }
    });
  })(Backbone.View);

  Backbone.View.prototype.dispose = function() {
    if (this.onBeforeClose) {
      this.onBeforeClose();
    }

    if (this.model) {
      if (!this.model.preserveBindings) {
        this.model.off();
      }

      if (!this.model.preserve) {
        this.model.clear({ silent: true });
        this.model._previousAttributes = {};
      }
    }

    if (this.collection) {
      if (!this.collection.preserveBindings) {
        this.collection.off();
      }

      if (!this.collection.preserve) {
        this.collection.reset([], { silent: true });
      }
    }

    this.off();

    if (this.$el) {
      this.remove();
    }

    if (this.onClose) {
      this.onClose();
    }
    return this;
  };

  Backbone.View.prototype.destroy = function() {
    if (this.isDestroyed || this.isClosed) {
      return; 
    }

    if (this.onBeforeClose) {
      this.onBeforeClose();
    }

    if (this.onBeforeDestroy) {
      this.onBeforeDestroy();
    }

    this.off();
    this.stopListening();
    $(this.el).empty();

    if (this.onClose) {
      this.onClose();
    }

    if (this.onDestroy) {
      this.onDestroy();
    }

    this.isDestroyed = this.isClosed = true; 

    return this;
  };

  Backbone.View.prototype.close = Backbone.View.prototype.destroy;
})(window.Backbone);


 })(window);